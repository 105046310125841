/** @jsx jsx */
import React from 'react'
import Navbar from 'components/navbar'
import SEO from 'components/seo'
import Footer from 'components/footer'
import { jsx, Flex, Box } from 'theme-ui'
import Intercom from 'components/intercom'

export default ({ mx = [0, 6], children }) => (
  <Flex
    sx={{
      flexDirection: 'column',
      minHeight: '100vh',
    }}
  >
    <Intercom />
    <SEO />
    <Navbar />
    <main id="content" sx={{ mx, mb: 3 }}>
      {children}
    </main>
    <Footer sx={{ marginTop: 'auto' }} />
  </Flex>
)
