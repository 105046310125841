import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/pteen/_src/a4i/website/src/components/layout.js";
import Navbar from 'components/navbar';
import Hero from 'components/hero';
import Banner from 'components/banner';
import Funnel from 'components/funnel';
import Explorers from 'components/explorers';
import Pricing from 'components/pricing';
import SampleScreen from 'components/sample-screen';
import SEO from 'components/seo';
import Footer from 'components/footer';
import { jsx, Flex, Box, Label, Input, Container, Button } from 'theme-ui';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Hero mdxType="Hero" />
    <SampleScreen mdxType="SampleScreen" />
    <Banner color='lightBrown' mdxType="Banner">
    
      <h1>From top of funnel to bottom, and everything in between</h1>
    </Banner>
    <Box my={4} mx={[0, 0, 6]} mdxType="Box">
      <Funnel mdxType="Funnel" />
    </Box>
    <Banner color='lightBrown' mdxType="Banner">
      <h1>Slice and dice your Intercom data as you like</h1>
    </Banner>
    <Box my={4} mb={5} mx={'auto'} mdxType="Box">
      <Explorers mdxType="Explorers" />
    </Box>
    <Box id="pricing" mdxType="Box" />
    <Banner color='brightGreen' mdxType="Banner">
      <h1>For a price that doesn't bite</h1>
    </Banner>
    <Box sx={{
      justifyContent: 'center'
    }} my={5} mdxType="Box">
      <Pricing mdxType="Pricing" />
    </Box>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      