export default {
  intercom: {
    appId: 'yk7555j4', //process.env.INTERCOM_APP,
  },
  user: {
    id:
      process.env.USER_ID ||
      (process.env.NODE_ENV === 'production' ? undefined : 'development'),
  },
}
